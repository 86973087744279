import {MetaAction} from "./metaReducer";
import {AccountLevel, ApiError, BrandMap, ErrorType, GetUserResponse, HandoffOptions} from "@devour/client";
import {IAction} from "../IAction";
import {isAPIError} from "@/utils/isAPIError";
import * as Sentry from "@sentry/react";
import { ThemePreference } from "@/types/Theme";
import {IMetaStore} from "@/redux/meta/InitialMetaStore";
import { AnonymousSpin } from "@/types/AnonymousSpin";
import {overwolfGameInfo} from "@/hooks/useOverwolfInterop";

export function setPreferredTheme(theme: ThemePreference): IAction<MetaAction.SET_PREFERRED_THEME, ThemePreference> {
    return {
        type: MetaAction.SET_PREFERRED_THEME,
        payload: theme,
    };
}

export function incrementLoading(): IAction<MetaAction.LOADING, number> {
    return {
        type: MetaAction.LOADING,
        payload: 1,
    };
}

export function decrementLoading(): IAction<MetaAction.LOADING, number> {
    return {
        type: MetaAction.LOADING,
        payload: -1,
    };
}

export function setIsOnOverwolf(payload: boolean): IAction<MetaAction.IS_ON_OVERWOLF, boolean> {
    return {
        type: MetaAction.IS_ON_OVERWOLF,
        payload,
    };
}

export function setOverwolfGameInfo(payload: overwolfGameInfo | null): IAction<MetaAction.OVERWOLF_GAME_INFO, overwolfGameInfo | null> {
    return {
        type: MetaAction.OVERWOLF_GAME_INFO,
        payload,
    };
}

const makeDefaultError: () => ApiError = () => ({type: ErrorType.APP,
    message: "An unknown error has occurred. Please contact support or try your request again."});
export async function makeApiError(error: any): Promise<ApiError> {
    let _error: ApiError;

    try {
        if (isAPIError(error)) {
            _error = error;
        } else {
            try {
                const jsonResponse = await (error as { json: () => Promise<ApiError> }).json();
                if (isAPIError(jsonResponse)) {
                    _error = jsonResponse;
                } else {
                    _error = makeDefaultError();
                }
            } catch (e) {
                _error = makeDefaultError();
            }
        }
    } catch (e) {
        _error = makeDefaultError();
    }

    return _error;
}
export async function addError(error?: { json: () => Promise<ApiError> } | ApiError): Promise<IAction<MetaAction.ADD_ERROR, any>> {
    const _error = await makeApiError(error);

    // check to see if failed to fetch error, if so, make a better error message.
    if (_error.message === "Failed to fetch") {
        _error.message = "There was an error with your connection to the server. Please confirm you are connected to the internet, your adblocker is off, and your network is not blocking any network request. If the issue continues, please try refreshing the page.";
    }

    if (_error.httpStatus >= 500) {
        if (isAPIError(_error)) {
            const sentryError = new Error(_error.message);
            sentryError.name = "ApiError";
            Sentry.setContext("apiError", _error);
            Sentry.captureException(sentryError);
        } else {
            Sentry.captureException(_error);
        }
    }

    return {
        type: MetaAction.ADD_ERROR,
        payload: _error,
    };
}

export function removeError(i: number = 0): IAction<MetaAction.REMOVE_ERROR, number> {
    return {
        type: MetaAction.REMOVE_ERROR,
        payload: i,
    };
}

export function addMenuOrderError(errorsCheckout: IMetaStore["menuOrderError"]): IAction<MetaAction.ADD_MENU_ORDER_ERROR, IMetaStore["menuOrderError"]> {
    return {
        type: MetaAction.ADD_MENU_ORDER_ERROR,
        payload: errorsCheckout,
    };
}

export function addDevourIqMenuOrderError(payload: IMetaStore["devourIqMenuOrderError"]): IAction<MetaAction.ADD_DEVOUR_IQ_MENU_ORDER_ERROR, IMetaStore["devourIqMenuOrderError"]> {
    return {
        type: MetaAction.ADD_DEVOUR_IQ_MENU_ORDER_ERROR,
        payload,
    };
}

export function removeDevourIqMenuOrderError(): IAction<MetaAction.REMOVE_DEVOUR_IQ_MENU_ORDER_ERROR> {
    return {
        type: MetaAction.REMOVE_DEVOUR_IQ_MENU_ORDER_ERROR,
    };
}

export function removeMenuOrderError(): IAction<MetaAction.REMOVE_MENU_ORDER_ERROR> {
    return {
        type: MetaAction.REMOVE_MENU_ORDER_ERROR,
    };
}

export function toggleSidebar(open: boolean): IAction<MetaAction.SIDEBAR, boolean> {
    return {
        type: MetaAction.SIDEBAR,
        payload: open,
    };
}

export function toggleGlobalContactModalVisible(open: boolean): IAction<MetaAction.GLOBAL_CONTACT_MODAL, boolean> {
    return {
        type: MetaAction.GLOBAL_CONTACT_MODAL,
        payload: open,
    };
}

export function updateCurrentUser(user: GetUserResponse): IAction<MetaAction.UPDATE_CURRENT_USER, GetUserResponse> {
    return {
        type: MetaAction.UPDATE_CURRENT_USER,
        payload: user,
    };
}

export function incrementModalCount(): IAction<MetaAction.MODAL_COUNT, number> {
    return {
        type: MetaAction.MODAL_COUNT,
        payload: 1,
    };
}

export function decrementModalCount(): IAction<MetaAction.MODAL_COUNT, number> {
    return {
        type: MetaAction.MODAL_COUNT,
        payload: -1,
    };
}

export function resetModalCount(): IAction<MetaAction.RESET_MODAL_COUNT, void> {
    return {
        type: MetaAction.RESET_MODAL_COUNT,
    };
}

export function addMenuOrder(restaurantId: string, menuOrderId: string): IAction<MetaAction.ADD_MENU_ORDER, { restaurantId: string, menuOrderId: string }> {
    return {
        type: MetaAction.ADD_MENU_ORDER,
        payload: {restaurantId,
            menuOrderId},
    };
}

export function removeMenuOrder(restaurantId: string): IAction<MetaAction.REMOVE_MENU_ORDER, string> {
    return {
        type: MetaAction.REMOVE_MENU_ORDER,
        payload: restaurantId,
    };
}

export function refreshMenuOrderCart(): IAction<MetaAction.REFRESH_MENU_ORDER_CART, void> {
    return {
        type: MetaAction.REFRESH_MENU_ORDER_CART,
    };
}

export function dismissWalletConnectPrompt(): IAction<MetaAction.DISMISS_WALLET_CONNECT_PROMPT, void> {
    return {
        type: MetaAction.DISMISS_WALLET_CONNECT_PROMPT,
    };
}

export function updateLastSearchedPlaceId(placeId: string): IAction<MetaAction.UPDATE_LAST_SEARCHED_PLACE_ID, string> {
    return {
        type: MetaAction.UPDATE_LAST_SEARCHED_PLACE_ID,
        payload: placeId,
    };
}

export function updateLastMenuOrderId(menuOrderId: string): IAction<MetaAction.UPDATE_LAST_MENU_ORDER_ID, string> {
    return {
        type: MetaAction.UPDATE_LAST_MENU_ORDER_ID,
        payload: menuOrderId,
    };
}

export function updateLastRestaurantId(restaurantId: string): IAction<MetaAction.UPDATE_LAST_RESTAURANT_ID, string> {
    return {
        type: MetaAction.UPDATE_LAST_RESTAURANT_ID,
        payload: restaurantId,
    };
}

export function updateLastBrandMap(brandMap: BrandMap): IAction<MetaAction.UPDATE_LAST_BRAND_MAP, BrandMap> {
    return {
        type: MetaAction.UPDATE_LAST_BRAND_MAP,
        payload: brandMap,
    };
}

export function toggleCartDrawer(open: boolean): IAction<MetaAction.TOGGLE_CART_DRAWER, boolean> {
    return {
        type: MetaAction.TOGGLE_CART_DRAWER,
        payload: open,
    };
}

export function updateAccountLevel(accountLevel: AccountLevel): IAction<MetaAction.UPDATE_ACCOUNT_LEVEL, AccountLevel> {
    return {
        type: MetaAction.UPDATE_ACCOUNT_LEVEL,
        payload: accountLevel,
    };
}

export function toggleOrderHandoff(handoff: HandoffOptions): IAction<MetaAction.TOGGLE_ORDER_HANDOFF, HandoffOptions> {
    return {
        type: MetaAction.TOGGLE_ORDER_HANDOFF,
        payload: handoff,
    };
}

export function updateLastDeletionEmailSent(lastCall: number): IAction<MetaAction.UPDATE_LAST_DELETION_EMAIL_CALL, number> {
    return {
        type: MetaAction.UPDATE_LAST_DELETION_EMAIL_CALL,
        payload: lastCall,
    };
}

export function updateSidebarModalActive(isActive: boolean): IAction<MetaAction.UPDATE_SIDEBAR_MODAL_ACTIVE, boolean> {
    return {
        type: MetaAction.UPDATE_SIDEBAR_MODAL_ACTIVE,
        payload: isActive,
    };
}

export function updateActiveGameModal(activeGame: string): IAction<MetaAction.UPDATE_ACTIVE_GAME_MODAL, string> {
    return {
        type: MetaAction.UPDATE_ACTIVE_GAME_MODAL,
        payload: activeGame,
    };
}

export function updateDpayPriceExpiryTime(dpayPriceExpiryTime: number): IAction<MetaAction.UPDATE_DPAY_PRICE_EXPIRY_TIME, number> {
    return {
        type: MetaAction.UPDATE_DPAY_PRICE_EXPIRY_TIME,
        payload: dpayPriceExpiryTime,
    };
}

export function removeDpayPriceExpiryTime(): IAction<MetaAction.REMOVE_DPAY_PRICE_EXPIRY_TIME, null> {
    return {
        type: MetaAction.REMOVE_DPAY_PRICE_EXPIRY_TIME,
        payload: null,
    };
}

export function updateForceRefreshTransactions(forceRefreshTransactions: boolean): IAction<MetaAction.UPDATE_FORCE_REFRESH_TRANSACTIONS, boolean> {
    return {
        type: MetaAction.UPDATE_FORCE_REFRESH_TRANSACTIONS,
        payload: forceRefreshTransactions,
    };
}

export function updateIsIndustryDismissed(update: boolean): IAction<MetaAction.UPDATE_IS_INDUSTRY_DISMISSED, boolean> {
    return {
        type: MetaAction.UPDATE_IS_INDUSTRY_DISMISSED,
        payload: update,
    };
}

export function updateIsIndustryDismissedForSession(update: boolean): IAction<MetaAction.UPDATE_IS_INDUSTRY_DISMISSED_FOR_SESSION, boolean> {
    return {
        type: MetaAction.UPDATE_IS_INDUSTRY_DISMISSED_FOR_SESSION,
        payload: update,
    };
}

export function toggleIndustryPassModal(open: boolean): IAction<MetaAction.TOGGLE_INDUSTRY_PASS_MODAL, boolean> {
    return {
        type: MetaAction.TOGGLE_INDUSTRY_PASS_MODAL,
        payload: open,
    };
}

export function toggleHideBottomNav(open: boolean): IAction<MetaAction.TOGGLE_HIDE_BOTTOM_NAV, boolean> {
    return {
        type: MetaAction.TOGGLE_HIDE_BOTTOM_NAV,
        payload: open,
    };
}

export function toggleMagicWalletsModal(open: boolean): IAction<MetaAction.TOGGLE_MAGIC_WALLETS_MODAL, boolean> {
    return {
        type: MetaAction.TOGGLE_MAGIC_WALLETS_MODAL,
        payload: open,
    };
}

export function updateIsGoFrensTutorialBannerDismissed(update: boolean): IAction<MetaAction.UPDATE_IS_GOFRENS_TUTORIAL_BANNER_DISMISSED, boolean> {
    return {
        type: MetaAction.UPDATE_IS_GOFRENS_TUTORIAL_BANNER_DISMISSED,
        payload: update,
    };
}

export function toggleStickyFooter(open: boolean): IAction<MetaAction.TOGGLE_STICKY_FOOTER, boolean> {
    return {
        type: MetaAction.TOGGLE_STICKY_FOOTER,
        payload: open,
    };
}

export function toggleChatOpen(open: boolean): IAction<MetaAction.TOGGLE_CHAT_OPEN, boolean> {
    return {
        type: MetaAction.TOGGLE_CHAT_OPEN,
        payload: open,
    };
}

export function toggleLoginOpen(open: boolean): IAction<MetaAction.TOGGLE_LOGIN_OPEN, boolean> {
    return {
        type: MetaAction.TOGGLE_LOGIN_OPEN,
        payload: open,
    };
}

export function updateAnonymousSpin(spin: AnonymousSpin | undefined): IAction<MetaAction.UPDATE_ANONYMOUS_SPIN, AnonymousSpin> {
    return {
        type: MetaAction.UPDATE_ANONYMOUS_SPIN,
        payload: spin,
    };
}

export function toggleMenuOrderUpdating(updating: boolean): IAction<MetaAction.IS_MENU_ORDER_UPDATING, boolean> {
    return {
        type: MetaAction.IS_MENU_ORDER_UPDATING,
        payload: updating,
    };
}

